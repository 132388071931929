'use client';

import React from 'react';

import { Header } from '../components/header';
import { Hero } from '../components/hero';
import { Skeleton } from '../components/skeleton';

export const PageClient = () => {
  return (
    <>
      <Header />

      <Hero />

      {Array(5)
        .fill(0)
        .map((item, index) => (
          <Skeleton key={index} />
        ))}
    </>
  );
};
