import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

const easeOutQuint = (x) => {
  return 1 - Math.pow(1 - x, 5);
};

export const Hero = () => {
  const [mounted, setMounted] = useState(false);

  const heroRef = useRef(null);

  useEffect(() => {
    setMounted(true);

    const handleScroll = () => {
      if (heroRef.current) {
        const heroHeight =
          (heroRef.current?.getBoundingClientRect?.()?.height || 0) * 1.5;

        const progress = Math.min(
          Math.max(window.scrollY / (heroHeight - window.innerHeight), 0),
          1,
        );

        heroRef.current.style.setProperty('--scroll', easeOutQuint(progress));
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('scroresizell', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <div
      ref={heroRef}
      className={classnames('hero', {
        'hero--mounted': mounted,
      })}
    >
      <div className="hero__inner">
        <div className="hero__wrap">
          {Array(4)
            .fill(null)
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={classnames('hero__orb', `hero__orb--${index + 1}`)}
                >
                  <img
                    src={`/hero/orb/orb-${index + 1}.png`}
                    alt=""
                    width="256"
                    height="256"
                    draggable="false"
                  />
                </div>
              );
            })}
        </div>

        <div className="hero__wrap">
          <div className="hero__phone">
            <div className="hero__phone__illustration">
              <img
                src="/hero/phone-vector.svg"
                alt=""
                width="452"
                height="919"
                draggable="false"
              />
              <img
                src="/hero/phone-bitmap.png"
                alt=""
                width="678"
                height="1379"
                draggable="false"
              />
            </div>

            <div className="hero__phone__content">
              <p className="hero__tag">Nouveau !</p>

              <h1 className="hero__title">
                Que feriez-vous avec
                <br />
                une baguette magique ?
              </h1>

              <p className="hero__description">
                Pas de magie chez Alan, mais un support gratuit, pas à pas et
                expert !
              </p>

              <div className="hero__carousel">
                <div className="hero__carousel__wrap">
                  {Array(4)
                    .fill(null)
                    .map((item, index) => {
                      return (
                        <img
                          key={index}
                          src={`/hero/carousel/item-${index + 1}.png`}
                          alt=""
                          width="689"
                          height="524"
                          draggable="false"
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
