import classnames from 'classnames';
import React from 'react';

import { MaxWidth } from '../max-width';

export const Header = () => {
  return (
    <div className={classnames('header')}>
      <MaxWidth>
        <div className="header__logo">
          <img src="/logo.svg" alt="Alan" />
        </div>
      </MaxWidth>
    </div>
  );
};
