import classnames from 'classnames';
import React from 'react';

import { MaxWidth } from '../max-width';

export const Skeleton = () => {
  return (
    <div className={classnames('skeleton')}>
      <MaxWidth>
        <h2 className="skeleton__title">
          Lorem ipsum dolor sit amet consectetur adipiscing elit.
        </h2>

        <p className="skeleton__description">
          À chaque mise à jour de l’application, notre but est de vous donner
          les bons outils pour prendre soin de votre santé et de votre
          bien-être. Retour sur nos dernières innovations.
        </p>

        <div className="skeleton__grid">
          <img src="/skeleton/grid-1.png" alt="" width="928" height="928" />
          <img src="/skeleton/grid-2.png" alt="" width="928" height="928" />
          <img src="/skeleton/grid-3.png" alt="" width="928" height="928" />
          <img src="/skeleton/grid-4.png" alt="" width="928" height="928" />
        </div>
      </MaxWidth>
    </div>
  );
};
